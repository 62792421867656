interface IconProps {
  stroke: string
  className?: string
}

export const Lesson = ({ stroke, className }: IconProps) => {
  return (
    <svg
      fill={stroke}
      width='25'
      height='28'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      xmlSpace='preserve'
      className={className}
    >
      <g>
        <g>
          <path
            d='M486.4,330.799c14.14,0,25.6-11.46,25.6-25.6v-230.4c0-14.14-11.46-25.6-25.6-25.6H25.6c-14.14,0-25.6,11.46-25.6,25.6
           v230.4c0,14.14,11.46,25.6,25.6,25.6h12.86c3.499,6.955,7.834,13.414,13.252,18.893C21.854,359.078,0,386.684,0,419.597v30.404
           c0,7.074,5.726,12.8,12.8,12.8h486.4c7.074,0,12.8-5.726,12.8-12.8v-30.404c0-32.913-21.854-60.518-51.712-69.905
           c5.419-5.478,9.754-11.938,13.252-18.893H486.4z M486.4,419.597v17.604H25.6v-17.604c0-26.462,21.538-48,48-48h60.8
           c13.824,0,26.598,5.888,35.977,16.589c4.847,5.572,14.404,5.572,19.251,0c9.378-10.701,22.153-16.589,35.977-16.589h60.8
           c13.824,0,26.598,5.888,35.977,16.589c4.847,5.572,14.404,5.572,19.251,0c9.378-10.701,22.153-16.589,35.977-16.589h60.8
           C464.862,371.597,486.4,393.135,486.4,419.597z M55.996,297.997c0-26.462,21.538-48,48-48s48,21.538,48,48s-21.53,48-48,48
           C77.534,345.997,55.996,324.459,55.996,297.997z M156.373,349.598c5.376-5.461,9.685-11.887,13.167-18.799h20.924
           c3.482,6.912,7.791,13.338,13.167,18.799c-8.508,2.722-16.546,6.861-23.629,12.51C172.919,356.45,164.881,352.32,156.373,349.598z
            M208,297.997c0-26.462,21.538-48,48-48s48,21.538,48,48s-21.538,48-48,48C229.538,345.997,208,324.459,208,297.997z
            M308.369,349.598c5.385-5.461,9.694-11.887,13.167-18.799h20.924c3.482,6.912,7.791,13.338,13.167,18.799
           c-8.508,2.722-16.546,6.861-23.629,12.51C324.915,356.45,316.877,352.32,308.369,349.598z M359.996,297.997
           c0-26.462,21.538-48,48-48c26.462,0,48,21.538,48,48s-21.538,48-48,48C381.534,345.997,359.996,324.459,359.996,297.997z
            M407.996,224.397c-40.585,0-73.6,33.015-73.6,73.6c0,2.466,0.495,4.796,0.725,7.202h-6.246c0.239-2.406,0.725-4.736,0.725-7.202
           c0-40.585-33.016-73.6-73.6-73.6s-73.6,33.015-73.6,73.6c0,2.466,0.486,4.796,0.725,7.202h-6.255
           c0.239-2.406,0.725-4.736,0.725-7.202c0-40.585-33.015-73.6-73.6-73.6s-73.6,33.015-73.6,73.6c0,2.466,0.495,4.796,0.725,7.202
           H25.6v-230.4h460.8v230.4h-5.53c0.239-2.406,0.725-4.736,0.725-7.202C481.596,257.412,448.58,224.397,407.996,224.397z'
          />
        </g>
      </g>
    </svg>
  )
}
